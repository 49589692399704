<template>
  <div class="vehicle">
    <!-- 基础车型 -->
    <Optional
      title="Model"
      valueKey="carTypeCode"
      labelKey="carTypeCn"
      :optionList="carTypeList"
      @on-click="onClickCarType(...arguments, 'carTypeList')"
    />
    <!-- 外色 -->
    <Optional
      title="Colour"
      valueKey="featureCode"
      labelKey="featureName"
      :optionList="outColorList"
      @on-click="onClickFeature(...arguments, 'outColorList')"
    />
    <!-- 内饰 -->
    <Optional
      title="Powertrain"
      valueKey="featureCode"
      labelKey="featureName"
      :optionList="interiorList"
      @on-click="onClickFeature(...arguments, 'interiorList')"
    />
    <!-- 选装包 -->
    <Optional
      title="Standard"
      valueKey="featureCode"
      labelKey="featureName"
      :optionList="optionalPackageList"
      @on-click="onClickFeature(...arguments, 'optionalPackageList')"
    />
    <!-- 车轮 -->
    <Optional
      title="Rim"
      valueKey="featureCode"
      labelKey="featureName"
      :optionList="wheelList"
      @on-click="onClickFeature(...arguments, 'wheelList')"
    />
    <!-- 冰箱 -->
    <Optional
      title="冰箱"
      valueKey="featureCode"
      labelKey="featureName"
      :optionList="refrigeratorList"
      @on-click="onClickFeature(...arguments, 'refrigeratorList')"
    />
  </div>
</template>
<script>
import { Toast } from 'vant'
import VehicleModelMixin from './vehicleModel'
import Optional from './optional'
export default {
  name: 'vehicleModel',
  mixins: [
    VehicleModelMixin
  ],
  components: {
    Optional
  },
  watch: {
    loading(value) {
      if (value) {
        Toast.loading({
          duration: 0,
          message: 'Loading...',
          loadingType: 'spinner',
          forbidClick: true
        })
      } else {
        Toast.clear()
      }
    }
  }
}
</script>
<style lang="less" scoped>
.loading {
  margin-top: 20px;
}
.vehicle {
  position: absolute;
  top: 2rem;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 23px 80px;
  overflow: auto;
  background-color: #fff;
}
</style>